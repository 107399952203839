<template>
    <div class="query">
        <h1>免费Q绑查询系统</h1>
        <div id="in">
            <el-input placeholder="请输入QQ" prefix-icon="el-icon-user-solid" v-model="qq" clearable />
            <div class="gekai"></div>
            <el-button v-on:click="query()" :loading="buttonloading" type="primary" plain>查询</el-button>
        </div>
        <div class="gekai"></div>
        <div id="out">
            <el-descriptions title="查询结果" direction="vertical" :column="1" border>
                <el-descriptions-item label="QQ号">
                    <el-tag size="qq">{{ retqq }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="头像">
                    <el-avatar :src="icon"></el-avatar>
                </el-descriptions-item>
                <el-descriptions-item label="手机号">{{ mobile }}</el-descriptions-item>
                <el-descriptions-item label="地区">{{ diqu }}</el-descriptions-item>
                <el-descriptions-item label="运营商">{{ isp }}</el-descriptions-item>
                <el-descriptions-item label="LOL">{{ lol }}</el-descriptions-item>
                <el-descriptions-item label="微博">{{ wb }}</el-descriptions-item>
                <el-descriptions-item label="QQ老密">{{ qqlm }}</el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QQquery',
    data() {
        return {
            qq: '',
            icon: 'https://q1.qlogo.cn/g?b=qq&nk=qq&s=100',
            retqq: '待查询',
            mobile: '待查询',
            diqu: '待查询',
            isp: '待查询',
            lol: '待查询',
            wb: '待查询',
            qqlm: '待查询',
            fullscreenLoading: false,
            buttonloading: false
        }
    },
    mounted() {
        this.$alert('永久免费不跑路', '公告', {
            confirmButtonText: '关闭'
        });
    },
    methods: {
        query() {
            this.mobile = '待查询';
            this.diqu = '待查询';
            this.isp = '待查询';
            this.lol = '待查询';
            this.wb = '待查询';
            this.qqlm = '待查询';
            this.retqq = this.qq;
            this.icon = "https://q1.qlogo.cn/g?b=qq&nk=" + this.qq + "&s=100";
            this.buttonloading = true;
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading'
            });
            this.$http({
                url: "https://api.chaqbang.tk/api/qbang?qq=" + this.qq
            }).then((res) => {
                loading.close();
                this.buttonloading = false;
                var data = res.data;
                var code = data.code;
                console.log(data);
                if (code != 200) {
                    console.log("空");
                    this.$message({ message: data.msg, showClose: true, type: 'warning' });
                    return;
                }
                this.$message({ message: '查询成功', showClose: true, type: 'success' });
                this.mobile = data.data.mobile;
                this.diqu = data.data.dist;
                this.isp = data.data.isp;
                this.lol = data.data.lol;
                this.wb = data.data.weibo;
                if (data.data.weibo != null) {
                    this.wb = 'https://weibo.com/u/' + data.data.weibo;
                }
                this.qqlm = data.data.password;
            }, (err) => {
                loading.close();
                this.buttonloading = false;
                console.log("请求出错: ", err);
                this.$message({ message: 'API请求出现错误', showClose: true, type: 'error' });
            });
        }
    }
}
</script>

<style scoped>
#in {
    width: 350px;
    display: flex;
    justify-content: flex-start;
    margin: auto;
}

#out {
    width: 550px;
    margin: auto;
}

.gekai {
    margin: 20px;
}
</style>
  